import React from 'react';
import { graphql } from 'gatsby';
import {
  ColorDataTableDesign,
  DontDo,
  DontDoItem,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Section,
} from 'docComponents';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Identity"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Colors" tierThree="Identity" />

      <PageNavigation>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Values</PageNavigationLink>
      </PageNavigation>

      <Section title="Usage">
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use identity colors as text."
            img="colors-identity-dont"
          />
          <DontDoItem
            type="do"
            text="use identity colors as accents."
            img="colors-identity-do"
          />
        </DontDo>
      </Section>
      <Section title="Values">
        <ColorDataTableDesign data={data} />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query identityColorsDesign {
    allColorsYaml(filter: { type: { eq: "Identity" } }) {
      edges {
        node {
          type
          colors {
            value
            common_name
          }
        }
      }
    }
  }
`;
